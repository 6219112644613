const APP_ENVIRONMENT = process?.env?.APP_ENVIRONMENT;
const APP_BASE_URL = process?.env?.APP_BASE_URL;
const CDN_BASE_URL = process?.env?.CDN_BASE_URL;
const APP_API_PATH = process?.env?.APP_API_PATH;
// const APP_BUNDLE_ID = process?.env?.APP_BUNDLE_ID;
// const APP_FACE_ID_URL = process?.env?.APP_FACE_ID_URL;
const APP_TRU_BASE_URL = process?.env?.APP_TRU_BASE_URL;
const APP_MIDDLEWARE_PATH = process?.env?.APP_MIDDLEWARE_PATH;

const APP_DATA_DOG_APPLICATION_ID = process?.env?.APP_DATA_DOG_APPLICATION_ID;
const APP_DATA_DOG_CLIENT_TOKEN = process?.env?.APP_DATA_DOG_CLIENT_TOKEN;
const APP_DATA_DOG_SITE = process?.env?.APP_DATA_DOG_SITE;
const APP_DATA_DOG_SERVICE_NAME = process?.env?.APP_DATA_DOG_SERVICE_NAME;
const APP_DATA_DOG_SESSION_SAMPLE_RATE = process?.env?.APP_DATA_DOG_SESSION_SAMPLE_RATE;
const APP_DATA_DOG_SESSION_REPLAY_SAMPLE_RATE =
  process?.env?.APP_DATA_DOG_SESSION_REPLAY_SAMPLE_RATE;
const APP_DATA_DOG_ENABLED = process?.env?.APP_DATA_DOG_ENABLED;

const MAINTENANCE_MODE_ENABLED = process?.env?.MAINTENANCE_MODE_ENABLED;

const APP_FIREBASE_API_KEY = process?.env?.APP_FIREBASE_API_KEY;
const APP_FIREBASE_APP_ID = process?.env?.APP_FIREBASE_APP_ID;
const APP_FIREBASE_AUTH_DOMAIN = process?.env?.APP_FIREBASE_AUTH_DOMAIN;
const APP_FIREBASE_PROJECT_ID = process?.env?.APP_FIREBASE_PROJECT_ID;
const APP_FIREBASE_STORAGE_BUCKET = process?.env?.APP_FIREBASE_STORAGE_BUCKET;
const APP_FIREBASE_MESSAGING_SENDER_ID = process?.env?.APP_FIREBASE_MESSAGING_SENDER_ID;
const APP_FIREBASE_MEASUREMENT_ID = process?.env?.APP_FIREBASE_MEASUREMENT_ID;

const getSecrets = () => {
  // Add all the variables present in the secret files here.
  return {
    APP_ENVIRONMENT,
    CDN_BASE_URL,
    APP_BASE_URL,
    APP_API_PATH,
    // APP_BUNDLE_ID,
    // APP_FACE_ID_URL,
    APP_TRU_BASE_URL,
    APP_MIDDLEWARE_PATH,
    APP_DATA_DOG_APPLICATION_ID,
    APP_DATA_DOG_CLIENT_TOKEN,
    APP_DATA_DOG_SITE,
    APP_DATA_DOG_SERVICE_NAME,
    APP_DATA_DOG_SESSION_SAMPLE_RATE,
    APP_DATA_DOG_SESSION_REPLAY_SAMPLE_RATE,
    APP_DATA_DOG_ENABLED,
    APP_FIREBASE_API_KEY,
    APP_FIREBASE_APP_ID,
    APP_FIREBASE_AUTH_DOMAIN,
    APP_FIREBASE_PROJECT_ID,
    APP_FIREBASE_STORAGE_BUCKET,
    APP_FIREBASE_MESSAGING_SENDER_ID,
    APP_FIREBASE_MEASUREMENT_ID,
    MAINTENANCE_MODE_ENABLED,
  };
};
export default getSecrets;
